import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Unique Plants and Palms",
    pageDescription:
      "Learn how a 4,000-acre nursery in Jacksonville, Florida saves on credit card processing fees and organizes its products to give customers a better experience.",
    title: "A blooming nursery gives customers a faster experience",
    subTitle: `How Unique Plants and Palms streamlined its operations with SpotOn.`,
    businessName: "Unique Plants and Palms",
    industry: "Retail",
    location: "Jacksonville, Florida",
    products: [
      spotonProducts.RETAIL,
    ],
    description:
      "A 17-year-old business, Unique Plants and Palms is a full-service nursery with 4,000 acres and an abundance of plant life requiring various conditions and care. With SpotOn, owner Michelle Grabill has been able to serve customers faster, tap into product sales data, and significantly reduce her credit card fees.",
    quoteData: {
      imgName: "unique-plants-and-palms.png",
      quote: `“SpotOn has helped us deliver a great customer experience. It’s nice for us to brand ourselves. Our name and the customers' items are on the receipts. It has worked out well. It's a nice combination.”`,
      personName: "Michelle Grabill",
      personTitle: "Owner, Unique Plants and Palms",
    },
    goal: `Unique Plants and Palms needed a point-of-sale system that could speed up the checkout process and provide a better experience for their customers. Grabill was also looking for ways to reduce unnecessary credit card fees and time spent on administrational tasks.`,
    solution: `With SpotOn Retail, Grabill's staff can ring up customers quickly with a catalog system, process transactions anywhere in the nursery with handhelds, and give customers itemized receipts. She also saves big on credit card fees and utilizes SpotOn reporting to make purchase decisions.`,
    results: {
      title: `The results`,
      stats: [
        { title: `$3000`, description: `saved in processing fees yearly` },
        {
          title: `5 – 10 minute`,
          description: `faster checkout times`,
        },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "Imagining gift baskets online",
        imageName: "imagine-that.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-17",
        href: "/case-studies/imagine-that",
        goals: [
          resourceGoals.CONNECT_AND_SELL_ONLINE,
          resourceGoals.REACH_NEW_CUSTOMERS,
        ],
    },
    {
        title: "Growing a flower business from the ground up",
        imageName: "flowers-on-phoenix.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-07-28",
        href: "/case-studies/flowers-on-phoenix",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
        ],
    },
    {
        title: "Getting more regulars with rewards",
        imageName: "strows-dry-cleaners.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-05-27",
        href: "/case-studies/strows-dry-cleaners",
        goals: [
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
  ];
  